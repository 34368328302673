import React from 'react'

const Twitter = () => (
  <svg width={29} height={24} viewBox="0 0 29 24">
    <path
      d="M28.613 2.842a11.459 11.459 0 01-3.372.953 6.028 6.028 0 002.58-3.35 11.484 11.484 0 01-3.728 1.467A5.771 5.771 0 0019.809 0c-3.24 0-5.869 2.713-5.869 6.058 0 .476.05.937.152 1.38-4.878-.253-9.203-2.663-12.101-6.33a6.191 6.191 0 00-.793 3.048c0 2.1 1.036 3.955 2.61 5.043a5.744 5.744 0 01-2.66-.757v.074c0 2.938 2.024 5.386 4.71 5.94a5.563 5.563 0 01-1.546.214c-.379 0-.747-.036-1.104-.107.747 2.407 2.913 4.159 5.483 4.206A11.55 11.55 0 010 21.279 16.252 16.252 0 008.997 24C19.797 24 25.7 14.77 25.7 6.762c0-.263-.005-.526-.016-.784a12.1 12.1 0 002.929-3.136"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default Twitter
