import React, { useRef, useEffect, useState, FC } from 'react'
import { Link, navigate } from 'gatsby'
import Urls from 'common/urls'
import LogoLockup from 'components/logo-lockup'
import { useCartContext } from 'context/cart'
import useScrollLock from 'hooks/use-scroll-lock'
import {
  Wrapper,
  MainNav,
  MainNavLink,
  UserNav,
  SiteTitle,
  Toggle,
  Copyright,
  ObservedPoint,
  ToggleContainer,
  ShopLink,
  SignInShopCartContainer,
  CartButton,
  CartIcon,
  CountBadge,
  MobileShopCart,
  SignInLink,
} from './styled'

type Props = {
  position?: HeaderPosition
  hasBorder?: boolean
  product?: any
}

const mainNavLinks = [
  { label: 'Home', link: Urls.home, hiddenOnDesktop: false },
  { label: 'For Providers', link: Urls.clinicians, hiddenOnDesktop: false },
  { label: 'Tests', link: Urls.shop, hiddenOnDesktop: false },
  { label: 'Our Science', link: Urls.science, hiddenOnDesktop: false },
  { label: 'Sign In', link: Urls.signIn, hiddenOnDesktop: true },
]

const Header: FC<Props> = ({ position = 'sticky', hasBorder = false }) => {
  const { getCount } = useCartContext()
  const headerRef = useRef<HTMLDivElement>(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  useScrollLock(isExpanded)
  // TODO: determine if we can get rid of this, this was probably for old transparent header
  const showBackground = !isIntersecting || isExpanded
  const hideShopLink =
    typeof window !== 'undefined' &&
    window.location.pathname === Urls.shop
  const hideCartButtonUrlSet = new Set([
    Urls.cart,
    `${Urls.purchaseSuccess}/`,
  ])
  const hideCartButton =
    typeof window !== 'undefined' &&
    hideCartButtonUrlSet.has(window.location.pathname)
  const cartIsEmpty = getCount() === 0

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(function(entries) {
      setIsIntersecting(entries[0].isIntersecting)
    })

    if (headerRef.current) {
      intersectionObserver.observe(headerRef.current)
    }

    return () => {
      intersectionObserver.disconnect()
    }
  }, [])

  const redirectToCart = async () => {
    await navigate(Urls.cart)
  }

  return (
    <>
      <ObservedPoint ref={headerRef} />
      <Wrapper
        position={position}
        isExpanded={isExpanded}
        key={showBackground.toString()}
        hasBorder={hasBorder}
      >
        <ToggleContainer isExpanded={isExpanded}>
          <Toggle
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(value => !value)}
            aria-label="Toggle menu"
          />
        </ToggleContainer>

        <MainNav isExpanded={isExpanded}>
          <ul>
            {mainNavLinks.map(item => (
              <li key={item.label}>
                <MainNavLink activeClassName="active" to={item.link} hiddenOnDesktop={item.hiddenOnDesktop}>
                  {item.label}
                </MainNavLink>
              </li>
            ))}
          </ul>
        </MainNav>

        <SiteTitle isExpanded={isExpanded}>
          <Link aria-label="Go to the home page" to="/">
            <LogoLockup />
          </Link>
        </SiteTitle>

        <UserNav isExpanded={isExpanded}>
          <ul>
            <li>
              {/*<Link activeClassName="active" to={Urls.doctors}>
                For Doctors
              </Link>*/}
            </li>
          </ul>
        </UserNav>


        <MobileShopCart isExpanded={isExpanded}>
          {!isExpanded && cartIsEmpty && (
            <ShopLink to={Urls.shop} hidden={hideShopLink}>
              Order a test
            </ShopLink>
          )}

          {!isExpanded && !cartIsEmpty && (
            <CartButton onClick={redirectToCart} hidden={hideCartButton}>
              <CartIcon />
              {Boolean(getCount()) && (
                <CountBadge>{getCount()}</CountBadge>
              )}
            </CartButton>
          )}
        </MobileShopCart>

        <SignInShopCartContainer isExpanded={isExpanded}>
          <CartButton onClick={redirectToCart} hidden={hideCartButton}>
            <CartIcon />
            {Boolean(getCount()) && (
              <CountBadge>{getCount()}</CountBadge>
            )}
          </CartButton>
          <ShopLink to={Urls.shop} hidden={hideShopLink}>
            Order a test
          </ShopLink>
          <SignInLink activeClassName="active" to={Urls.signIn}>
            Sign In
          </SignInLink>
        </SignInShopCartContainer>

        {isExpanded && (
          <Copyright>
            © {new Date().getFullYear()} <span translate="no">Fellow</span>. All Rights Reserved.
          </Copyright>
        )}
      </Wrapper>
    </>
  )
}

export default Header
