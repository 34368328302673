import React, { FC, SVGProps } from 'react'

type Props = SVGProps<SVGSVGElement> & {
  viewbox?: string
}

// TODO: make this more general for all SVGs,
// or rename to something like Icon since that's how we use this
const Svg: FC<Props> = ({
  children,
  viewbox = '0 0 87 87',
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox={viewbox}
    {...rest}
  >
    {children}
  </svg>
)

export default Svg
