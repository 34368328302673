import React from 'react'

const RightArrow = () => (
  <svg width={23} height={18} viewBox="0 0 23 18">
    <g stroke="#FFF" strokeWidth={3} fill="none" fillRule="evenodd">
      <path d="M12.63 16.508L20 9.254 12.63 2M19.137 8.99l-18.99.04" />
    </g>
  </svg>
)

export default RightArrow
