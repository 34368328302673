import React, { FC } from 'react'

const Logo: FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    width="1em"
    viewBox="0 0 91 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title><span translate="no">Fellow</span></title>
    <g fill="currentColor" transform="translate(0.000000, 0.000000)">
      <polygon points="0 0.103397059 0 24.3172206 5.80698651 24.3172206 5.80698651 15.0900147 11.7688375 15.0900147 11.7688375 9.65766176 5.80698651 9.65766176 5.80698651 5.53501471 12.9617557 5.53501471 12.9617557 0.103397059"></polygon>
      <path d="M17.9603321,13.2251765 C18.185275,12.1448824 19.24366,10.1160588 22.0028149,10.1160588 C24.7619699,10.1160588 25.82122,12.1448824 26.0452977,13.2251765 L17.9603321,13.2251765 Z M31.3718294,15.4497353 C31.3718294,9.92032353 27.3930804,5.53532353 21.9706596,5.53532353 C16.5480947,5.53532353 12.6346657,10.2147353 12.6346657,15.2862059 C12.6346657,20.6848824 16.9656809,24.9062059 22.0665487,24.9062059 C23.8962282,24.9062059 25.6607319,24.3497353 27.1359822,23.2703235 C28.6441088,22.2229706 29.8958581,20.6528235 30.7615997,18.6238529 L25.3077447,18.6238529 C24.5701916,19.5725294 23.7035848,20.3256176 22.0665487,20.3256176 C19.981934,20.3256176 18.2174303,19.0488529 17.8967425,16.8892941 L31.2429198,16.8892941 C31.3073746,16.4641471 31.3718294,16.1698824 31.3718294,15.4497353 L31.3718294,15.4497353 Z"></path>
      <polygon points="32.3995587 24.3176471 37.7252251 24.3176471 37.7252251 0.103823529 32.3995587 0.103823529"></polygon>
      <polygon points="39.3560609 24.3176471 44.6818716 24.3176471 44.6818716 0.103823529 39.3560609 0.103823529"></polygon>
      <path d="M55.4716597,19.8016765 C57.7819383,19.8016765 59.7390854,17.8713824 59.7390854,15.2209412 C59.7390854,12.5706471 57.7819383,10.6394706 55.4716597,10.6394706 C53.1622463,10.6394706 51.204234,12.5706471 51.204234,15.2209412 C51.204234,17.8713824 53.1622463,19.8016765 55.4716597,19.8016765 M55.4716597,5.568 C61.9524654,5.568 65.0647518,10.7702059 65.0647518,15.2209412 C65.0647518,19.6709412 61.9524654,24.8731471 55.4716597,24.8731471 C48.990854,24.8731471 45.8785676,19.6709412 45.8785676,15.2209412 C45.8785676,10.7702059 48.990854,5.568 55.4716597,5.568"></path>
      <polygon points="85.0669451 6.15664706 82.4036793 17.4456176 82.3399455 17.4456176 79.3881472 6.15664706 74.8966438 6.15664706 71.977145 17.4456176 71.9134112 17.4456176 69.2492803 6.15664706 63.7316914 6.15664706 69.1856906 24.3173824 74.1267913 24.3173824 77.1101681 12.5697353 77.1747671 12.5697353 80.1587207 24.3173824 85.0993888 24.3173824 90.5538206 6.15664706"></polygon>
    </g>
  </svg>
)

export default Logo
