import React from 'react'

const Facebook = () => (
  <svg width={12} height={24} viewBox="0 0 12 24">
    <path
      d="M2.57 7.685V5.35c0-1.03.028-2.619.828-3.602C4.242.707 5.4 0 7.39 0 10.634 0 12 .432 12 .432l-.643 3.56s-1.071-.29-2.07-.29c-1.001 0-1.897.334-1.897 1.268v2.715h4.1l-.285 3.477H7.39v12.077H2.57V11.162H0V7.685h2.57z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default Facebook
