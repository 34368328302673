import React, { useState, FormEvent } from 'react'
import { validate } from 'email-validator'
import RightArrow from 'components/icons/right-arrow'

import { Input, Button, Wrapper, SubmitSpinner } from './styled'

type Props = {
  //@ts-ignore
  onSubmit: DebouncedFunc<(email: string) => Promise<void>>
  inverted?: boolean
  source: string
}

const Form = ({ onSubmit, inverted = false, source }: Props) => {
  const [email, setEmail] = useState('')
  const [buttonHidden, setButtonHidden] = useState(false)

  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setButtonHidden(true)
    await onSubmit(email)
  }

  return (
    <Wrapper id={source} onSubmit={handleSubmit} inverted={inverted}>
      <Input
        type="email"
        placeholder="Enter your email"
        aria-label="Email input"
        inverted={inverted}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button
        disabled={!validate(email)}
        type="submit"
        aria-label="Submit Email"
        title={`${source} Email Capture Submit Button`}
        hidden={buttonHidden}
      >
        <RightArrow />
      </Button>
      {buttonHidden ? <SubmitSpinner inverted={inverted} /> : null}
    </Wrapper>
  )
}

export default Form
