import React, { useState } from 'react'
import api from 'common/api'
import Form from './form'
import { Title } from './styled'
import debounce from 'lodash/debounce'

type Props = {
  className?: string
  inverted?: boolean
  title?: string
  source: string
  afterSubmit?: any
  successMessage?: string
}

const LeadEmailCaptureForm = ({
  className,
  title,
  source,
  afterSubmit,
  successMessage = 'Thank you!',
  inverted = false,
}: Props) => {
  const [submitted, setSubmitted] = useState(false)
  const id = `email-capture-${source}`

  const handleSubmit = debounce(async (email: string) => {
    await api.subscribe(email, { source: source })
    gtag('event', `Form Submitted`, {
      event_category: 'Lead Capture',
      event_label: source,
    })
    setSubmitted(true)
    if (afterSubmit) {
      afterSubmit()
    }
  }, 2000)

  return (
    <div id={id} className={className}>
      {title && <Title>{title}</Title>}
      {submitted ? (
        <small>{successMessage}</small>
      ) : (
        <Form onSubmit={handleSubmit} inverted={inverted} source={source} />
      )}
    </div>
  )
}

export default LeadEmailCaptureForm
