import React from 'react'
import { Link } from 'gatsby'
import FacebookIcon from 'components/icons/facebook'
import InstagramIcon from 'components/icons/instagram'
import TwitterIcon from 'components/icons/twitter'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Urls from 'common/urls'
import {
  Wrapper,
  Inner,
  Logo,
  Social,
  Links,
  Newsletter,
  Copyright,
} from './styled'

const links = [
  { label: 'For Providers', href: Urls.clinicians },
  { label: 'Vasectomy Test', href: Urls.vasectomyKit },
  { label: 'Semen Analysis', href: Urls.kit },
  { label: 'Cryopreservation', href: Urls.kitPlusCryo },
  { label: 'Our Science', href: Urls.science },
  { label: 'Our Story', href: Urls.story },
  { label: 'Careers', href: Urls.careers },
  { label: 'Contact', href: Urls.contact },
  { label: 'Terms & Conditions', href: Urls.terms },
  { label: 'Privacy Policy', href: Urls.privacy },
  { label: 'Reviews', href: Urls.reviews },
  { label: 'FAQ', href: Urls.faq },
  { label: 'Sign In', href: Urls.signIn },
]

const Footer = () => (
  <Wrapper>
    <Inner>
      <Logo />
      <Newsletter
        title="Get the latest on fertility"
        source="newsletter-signup"
        inverted={true}
      />
      <Links>
        {links.map(link => (
          <li key={link.href}>
            <Link to={link.href}>{link.label}</Link>
          </li>
        ))}
      </Links>
      <Social>
        <li>
          <a href={Urls.facebook} aria-label="Click here to visit our Facebook page" target="_blank" rel="noopener noreferrer">
            <FacebookIcon />
          </a>
        </li>
        <li>
          <a href={Urls.instagram} target="_blank" aria-label="Click here to visit our Instagram page" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
        </li>
        <li>
          <a href={Urls.twitter} target="_blank" aria-label="Click here to visit our Twitter page" rel="noopener noreferrer">
            <TwitterIcon />
          </a>
        </li>
      </Social>
      <Copyright>
        <p>CLIA #05D2177822 - COLA #31245</p>
        <p>© {new Date().getFullYear()} <span translate="no">Fellow</span>. All Rights Reserved.</p>
      </Copyright>
    </Inner>
  </Wrapper>
)

export default Footer
