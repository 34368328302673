import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import theme from 'styles/theme'
import { font, heading } from 'styles/typography'
import mq from 'styles/mq'
import ShoppingCart from 'components/icons/shopping-cart'

export const Wrapper = styled.header<{
  position: HeaderPosition
  isExpanded?: boolean
  hasBorder?: boolean
}>`
  position: ${props => props.position};
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  transition: background-color 0.3s, color 0.3s;
  z-index: 500;
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);

  ${mq.medium} {
    align-items: center;
  }

  ${props =>
    props.isExpanded &&
    css`
      position: fixed;
      height: 100%;
      padding: 0 3rem;
      overflow-y: auto;

      ${mq.medium} {
        padding: 0 5rem;
      }
    `}

  ${props =>
    props.hasBorder &&
    css`
      border-bottom: 0.1rem solid ${theme.color.border.light};
    `}

  a {
    &:hover,
    &:focus {
      color: ${theme.color.accent.primary};
    }
  }
`

export const SiteTitle = styled.span<{ isExpanded?: boolean }>`
  height: 7rem;
  font-size: 6rem;
  margin: 0;
  padding-left: 5.5rem;

  span {
    display: none;
  }

  a,
  svg {
    display: block;
  }

  ${props =>
    props.isExpanded
      ? css`
          order: 10;
          align-self: center;
          padding-left: 0;
          height: 3rem;
          margin-left: 0;
        `
      : css`
          padding-top: 2.3rem;
          margin-left: -3.6rem;

          ${mq.small} {
            margin-left: 0;
          }

          ${mq.medium} {
            padding-top: 1.5rem;
            margin-left: 3rem;
          }

          ${mq.desktop} {
            margin-left: -15rem;
          }
        `}

  ${mq.medium} {
    font-size: 9.1rem;
  }

  ${mq.desktop} {
    padding-left: 0;
  }
`

export const Nav = styled.nav`
  font: bold 2.4rem ${font.headings};
  flex: 1;

  ${mq.medium} {
    font-size: 1.3rem;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    margin-top: 0.2em;

    ${mq.medium} {
      &:not(:first-of-type) {
        margin-left: 1.5rem;
      }
    }
  }
`

export const MainNav = styled(Nav as any)<{ isExpanded?: boolean }>`
  display: none;

  ${mq.desktop} {
    display: block;
    padding-left: 5rem;
    max-width: 43rem;
  }

  ul {
    ${props =>
      !props.isExpanded &&
      css`
        display: none;
      `}

    ${mq.desktop} {
      display: block;
    }
  }

  ${props =>
    props.isExpanded
      ? css`
          display: block;
          flex: 0 0 auto;
          width: 100%;
          counter-reset: main-menu;

          li {
            display: block;
            padding-bottom: 2.4rem;

            &:not(:last-child) {
              margin: 0 0 4.6rem;
            }
          }

          a {
            display: flex;
            // had to change align-items because
            // kit + cryo <li> is two lines tall
            // styling otherwise is tough bc the
            // index is just a counter pseudo-element
            align-items: center;
            justify-content: space-between;
            position: relative;

            &:before {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              border-bottom: 1px solid currentColor;
              opacity: 0.16;
            }

            &:after {
              font-size: 7.2rem;
              opacity: 0.1;
            }

            &.active {
              color: ${theme.color.accent.primary};

              &:before {
                border-bottom: 3px solid ${theme.color.accent.primary};
                opacity: 1;
              }

              &:after {
                opacity: 1;
                color: ${theme.color.text.dark};
              }
            }
          }
        `
      : css`
          margin-left: -2.5rem;
          ${mq.desktop} {
            margin-left: 0rem;
          }

          a.active {
            position: relative;

            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              margin: 0.2em 0;
              border-bottom: 2px solid ${theme.color.accent.primary};
            }
          }
        `}
`

export const MainNavLink = styled(Link)<{ hiddenOnDesktop: boolean }>`
  ${props =>
    props.hiddenOnDesktop &&
    css`
      ${mq.desktop} {
        visibility: hidden;
      }
    `}
`

export const UserNav = styled(Nav as any)<{ isExpanded?: boolean }>`
  display: none;
  text-align: right;

  li:last-child .label {
    ${mq.medium} {
      display: inline;
    }
  }

  .active {
    display: none;
  }

  ${props =>
    props.isExpanded
      ? css`
          display: inline;
          ${heading.s};
          flex: 0 0 auto;
          width: 100%;
          text-align: left;
          margin: 3rem 0;

          li:not(:last-child) {
            opacity: 0.4;
          }
        `
      : css`
          li:not(:last-child) {
            display: none;

            ${mq.medium} {
              display: inline;
            }
          }
        `}
`

export const Toggle = styled.button<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.5rem;
  height: 1.1rem;
  padding: 0;
  margin-top: 2.8rem;
  border: none;
  appearance: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;

  &:before,
  &:after {
    content: '';
    display: block;
    border-top: 3px solid currentColor;
    width: 2.5rem;
  }

  ${mq.medium} {
    margin-top: 0;
  }

  ${mq.desktop} {
    display: none;
  }

  ${props =>
    props.isExpanded &&
    css`
      &:before {
        margin: auto 0;
      }

      &:after {
        display: none;
      }
    `}
`

export const Copyright = styled.span`
  display: block;
  flex: 1;
  order: 20;
  text-align: right;
  align-self: center;
  font-size: 1.2rem;

  ${mq.medium} {
    display: none;
  }
`

export const ObservedPoint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 7rem;
`

export const ToggleContainer = styled.div<{ isExpanded: boolean }>`
  ${mq.medium} {
    width: 18rem;
  }

  ${mq.desktop} {
    display: none;
  }

  ${props =>
    props.isExpanded &&
    css`
      padding-left: 0;
    `}

  ${props =>
    !props.isExpanded &&
    css`
      padding-left: 3rem;

      ${mq.medium} {
        padding-left: 5rem;
      }
    `}
`

export const MobileShopCart = styled.div<{ isExpanded: boolean }>`
  display: initial;
  height: 7rem;
  padding-right: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 6.4rem;

  ${mq.small} {
    min-width: 6.5rem;
  }

  ${mq.medium} {
    display: none;
  }

  ${props =>
    props.isExpanded &&
    css`
      display: none;
    `}
`

export const SignInShopCartContainer = styled.div<{ isExpanded: boolean }>`
  display: none;
  height: 7rem;
  width: 25rem;
  padding-right: 0.6rem;

  ${mq.small} {
    padding-right: 2.4rem;
  }

  ${mq.medium} {
    margin-right: 1.2rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  ${mq.desktop} {
    margin-right: 2rem;
  }

  ${props =>
    props.isExpanded &&
    css`
      visibility: hidden;
    `}
`

export const ShopLink = styled(Link)<{ hidden: boolean }>`
  font: bold 1.4rem ${font.headings};
  margin-right: 1rem;

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`

export const CartButton = styled.div<{ hidden: boolean }>`
  position: relative;
  padding-top: 2rem;
  margin-left: 1rem;
  cursor: pointer;

  ${mq.small} {
    margin-left: 1.2rem;
  }

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`

export const CartIcon = styled(ShoppingCart)`
  width: 4rem;
  height: 4rem;
  color: ${theme.color.text.dark};
`

export const CountBadge = styled.div`
  position: absolute;
  top: 1rem;
  left: 2.3rem;
  width: 2rem;
  height: 2rem;
  padding: 0.4rem 0;
  border-radius: 50%;
  background-color: ${theme.color.accent.primary};
  color: ${theme.color.text.light};
  text-align: center;
  font: bold 1.2rem ${font.headings};
`
export const SignInLink = styled(Link)`
  font: bold 1.4rem ${font.headings};
  margin-right: 1rem;
  display: none;
  padding-right: 2rem;

  ${mq.desktop} {
    display: initial;
  }
`