import React, { FC } from 'react'

import GlobalStyle from 'styles/global'
import FellowHeader from 'components/fellow-header'
import Footer from 'components/footer'
import { CartProvider } from 'context/cart'
import MaintenancePage from 'components/maintenance'
// import Protected from 'components/protected'
import { withIntersectionObserver } from 'common/polyfills'

type Props = {
  headerPosition?: HeaderPosition
  withHeaderBorder?: boolean
}

const Layout: FC<Props> = ({ children, headerPosition, withHeaderBorder }) => (
  <MaintenancePage>
    {/* <Protected> */}
    <CartProvider>
      <GlobalStyle />
      <FellowHeader position={headerPosition} hasBorder={withHeaderBorder} />
      {children}
      <Footer />
    </CartProvider>
    {/* </Protected> */}
  </MaintenancePage>
)

export default withIntersectionObserver(Layout)
