import React, { FC } from 'react'
import Header from 'components/header'

type Props = {
  position?: HeaderPosition
  hasBorder?: boolean
}

// TODO: refactor me! We no longer need this wrapper
// We used to need a component that wraps the <Header />
// because Jest and Storybook cannot run the graphql query
// needed to get the product information about the kit
const FellowHeader: FC<Props> = ({
  position = 'sticky',
  hasBorder = false,
}) => {
  return <Header position={position} hasBorder={hasBorder} />
}

export default FellowHeader
