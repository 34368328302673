import config from 'common/config'

const post = async <T>(url: string, params: object) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
  return (await response.json()) as T
}

type PostResponse = {
  status: number
}

const subscribe = async (email: string, customAttributes?: object) => {
  try {
    const url = `${config.fellowApi}leads/`

    const post_data = {
      email: email,
      custom_attributes: customAttributes,
    }
    const response = await post<PostResponse>(url, post_data)
    if (response.status === 200) return true
    return false
  } catch (err) {
    return false
  }
}

const postPromoCode = async (code: string, skus: string): Promise<PromoCode> => {
  try {
    // SKUs are passed in as a comma separated string of product SKUs. (e.g. `sku_123,sku_456,sku_789`)
    const url = `${config.fellowApi}check-promo-code/?code=${code}&skus=${skus}`

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application, json',
      },
    })
    const promoCodeData = await response.json()
    if (promoCodeData.error) {
      throw new Error(promoCodeData.error)
    }

    // The is_referral parameter is being used to conditionally render the clinic_sharing_agreement checkbox in the
    // cart, and might not be sent by the server. In that case set it to true so that everyone agrees to the checkbox
    // for now.
    const promoCodeInstance: PromoCode = {
      percent_off: promoCodeData.percent_off,
      amount_off: promoCodeData.amount_off,
      is_referral: promoCodeData.is_referral === false ? false : true,
    }
    return await new Promise(resolve => {
      resolve(promoCodeInstance)
    })
  } catch (err) {
    // Let the caller handle the error as needed
    throw new Error(err.message)
  }
}

const processPayment = async (
  values: PaymentRequestType
): Promise<{ status: string }> => {
  try {
    const url = `${config.fellowApi}pay/`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })

    const paymentStatus = {
      status: response.status === 200 ? 'success' : 'failure',
    }
    return await new Promise(resolve => {
      resolve(paymentStatus)
    })
  } catch (err) {
    const paymentStatus = {
      status: 'failure',
    }
    return await new Promise(resolve => {
      resolve(paymentStatus)
    })
  }
}
export default {
  subscribe,
  postPromoCode,
  processPayment,
}
