import styled from 'styled-components'
import mq from 'styles/mq'
import Whale from 'components/icons/whale'

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const LogoWhale = styled(Whale)`
  font-size: 2.2rem;
  margin-left: 0.2rem;

  ${mq.medium} {
    font-size: 4rem;
  }
`
