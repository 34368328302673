import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ShoppingCart: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 204 204">
    <path
      d="M5.343 0a5.343 5.343 0 100 10.685h21.315C35.5 32.585 44.21 54.526 52.98 76.467l-8.07 19.422c-.675 1.62-.485 3.572.49 5.031.977 1.458 2.709 2.379 4.464 2.371h89.044c2.822.039 5.419-2.52 5.419-5.342 0-2.823-2.597-5.383-5.419-5.343h-81.03l4.619-11.02 85.761-6.844c2.244-.177 4.276-1.926 4.786-4.119L163.73 24.32c.699-3.119-2.035-6.522-5.231-6.511H41.016l-5.788-14.47C34.435 1.381 32.388 0 30.275 0H5.343zM45.3 28.494h106.464l-8.293 35.952L62.275 70.9 45.301 28.494zm22.373 78.359c-9.773 0-17.81 8.036-17.81 17.809 0 9.772 8.037 17.809 17.81 17.809 9.772 0 17.808-8.037 17.808-17.809s-8.036-17.809-17.808-17.809zm53.426 0c-9.772 0-17.809 8.037-17.809 17.809s8.037 17.809 17.809 17.809 17.809-8.037 17.809-17.809c0-9.773-8.037-17.809-17.809-17.809zm-53.426 10.685c3.997 0 7.123 3.126 7.123 7.124 0 3.997-3.126 7.123-7.123 7.123-3.998 0-7.124-3.126-7.124-7.123 0-3.998 3.126-7.124 7.124-7.124zm53.426 0c3.997 0 7.124 3.126 7.124 7.124 0 3.997-3.127 7.123-7.124 7.123s-7.123-3.126-7.123-7.123c0-3.998 3.126-7.124 7.123-7.124z"
      fill="currentColor"
    />
  </Svg>
)

export default ShoppingCart
