import config from 'common/config'

export default {
  home: '/',
  kit: '/kit',
  kitPlusCryo: '/kit-plus-cryo',
  vasectomyKit: '/vasectomy-kit',
  story: '/about',
  science: '/science',
  blog: '/blog',
  sperm101: '/sperm-101',
  clinicians: '/clinicians',
  faq: '/faq',
  careers: '/careers',
  contact: '/contact',
  privacy: '/privacy',
  terms: '/terms',
  covid: '/covid-19',
  reviews: '/reviews',

  // Store
  shop: '/shop',
  cart: '/cart',
  purchaseSuccess: '/purchase-success',

  // Social
  facebook: 'https://www.facebook.com/fellowhealth/',
  instagram: 'https://www.instagram.com/fellow/',
  twitter: 'https://twitter.com/fellow',

  // Sign-in
  signIn: `${config.fellowYouDot}accounts/login`,  // the trailing slash is included in the environment variable

  // TypeForm
  leadGenForm: 'https://meetfellow.typeform.com/to/C7IB6L',

  // Assets
  sampleResults: 'https://fellow-pdfs.s3-us-west-2.amazonaws.com/John_Doe_Sample_Report.pdf',

  // Partners
  requestATest: 'https://requestatest.com/required-blood-testing-for-fellow-cryopreservation',
}

export const blogCategoryUrl = (slug: string) => `/blog/categories/${slug}`

export const blogAuthorUrl = (slug: string) => `/blog/authors/${slug}`
