import React from 'react'

const Instagram = () => (
  <svg width={23} height={23} viewBox="0 0 23 23">
    <path
      d="M12.72.181c2.084.004 2.517.02 3.566.068 1.213.055 2.042.248 2.767.53.75.292 1.387.681 2.02 1.315a5.589 5.589 0 011.316 2.02c.282.725.475 1.554.53 2.768.055 1.216.068 1.605.068 4.702v.31c0 2.815-.015 3.217-.068 4.392-.055 1.213-.248 2.042-.53 2.767a5.589 5.589 0 01-1.316 2.02 5.589 5.589 0 01-2.02 1.316c-.725.282-1.554.475-2.767.53-1.217.055-1.605.068-4.702.068h-.311c-2.814 0-3.217-.015-4.391-.068-1.214-.055-2.043-.248-2.768-.53a5.589 5.589 0 01-2.02-1.316 5.589 5.589 0 01-1.315-2.02c-.282-.725-.475-1.554-.53-2.767C.2 15.237.185 14.804.18 12.72v-2.272c.004-2.084.02-2.518.068-3.566.055-1.214.248-2.043.53-2.768a5.589 5.589 0 011.315-2.02A5.589 5.589 0 014.114.78C4.84.497 5.668.304 6.882.249 7.93.2 8.364.185 10.448.18zm-.274 2.054h-1.725c-2.266.003-2.667.017-3.745.066-1.112.052-1.716.237-2.118.393a3.54 3.54 0 00-1.31.853c-.4.4-.647.78-.854 1.311-.156.402-.341 1.006-.393 2.118-.05 1.078-.063 1.48-.066 3.745v1.725c.003 2.266.017 2.668.066 3.746.052 1.112.237 1.715.393 2.117.207.532.454.912.853 1.311.4.4.78.647 1.311.854.402.156 1.006.342 2.118.392 1.078.05 1.48.064 3.745.066h1.725c2.266-.002 2.668-.016 3.746-.066 1.112-.05 1.715-.236 2.117-.392a3.54 3.54 0 001.311-.854c.4-.399.647-.779.854-1.311.156-.402.342-1.005.392-2.117.05-1.078.064-1.48.066-3.746v-1.725c-.002-2.266-.016-2.667-.066-3.745-.05-1.112-.236-1.716-.392-2.118a3.54 3.54 0 00-.854-1.31c-.399-.4-.779-.647-1.311-.854-.402-.156-1.005-.341-2.117-.393-1.078-.05-1.48-.063-3.746-.066zm-.862 3.493a5.856 5.856 0 110 11.712 5.856 5.856 0 010-11.712zm0 2.055a3.801 3.801 0 100 7.602 3.801 3.801 0 000-7.602zm6.087-3.655a1.369 1.369 0 110 2.738 1.369 1.369 0 010-2.738z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default Instagram
