import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react'
import { getItems, updateCart } from 'utils/cart'
import useAllKitProducts from 'hooks/use-all-kit-products'

type QuantityDelta = number
type ItemsUpdate = {
  [sku: string]: QuantityDelta
}
type CartContext = {
  items: CartItems
  updateItems: (itemsUpdate: ItemsUpdate) => void
  clear: () => void
  getCount: () => number
  promoCode: Maybe<PromoCode>
  applyPromoCode: (promoCode: PromoCode) => void
  removePromoCode: () => void
  ship: number
  applyShip: (ship: number) => void
  expeditedShippingNoticeAcknowledged: boolean
  setExpeditedShippingNoticeAcknowledged: (value: boolean) => void
}

export const CartContext = createContext({} as CartContext)

export const useCartContext = () => useContext(CartContext)

export const CartProvider: FC = props => {
  const [items, setItems] = useState<CartItems>(() => {
    let cart: CartItems = {}
    const cartFromLocalStorage = getItems()
    if (cartFromLocalStorage) {
      cart = { ...cartFromLocalStorage }
      for (const [sku, quantity] of Object.entries(cart)) {
        if (quantity === 0) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete cart[sku]
        }
      }
    }
    return cart
  })

  const [promoCode, setPromoCode] = useState<Maybe<PromoCode>>(null)
  const [
    expeditedShippingNoticeAcknowledged,
    setExpeditedShippingNoticeAcknowledged,
  ] = useState(false)
  const [ship, setShip] = useState(0)

  const allKitProducts = useAllKitProducts()

  const updateItems = (itemsUpdate: ItemsUpdate) => {
    const updatedItems: CartItems = { ...items }
    allKitProducts.forEach(({ id }) => {
      if (id in items) {
        if (typeof itemsUpdate[id] !== 'undefined') {
          updatedItems[id] += itemsUpdate[id]
        }
      } else {
        if (id in itemsUpdate) {
          updatedItems[id] = itemsUpdate[id]
        }
      }
    })
    setItems(updatedItems)
  }

  const getCount = () => {
    let totalCount = 0
    Object.values(items).forEach(itemQuantity => {
      totalCount += itemQuantity
    })
    return totalCount
  }

  const clear = () => {
    setItems({})
  }

  const applyPromoCode = (promoCodeObject: PromoCode) => {
    setPromoCode(promoCodeObject)
  }

  const removePromoCode = () => {
    setPromoCode(null)
  }

  const applyShip = (shipping_amount: number) => {
    setShip(shipping_amount)
  }

  useEffect(() => {
    updateCart(items)
  }, [items])

  return (
    <CartContext.Provider
      {...props}
      value={{
        items,
        updateItems,
        getCount,
        clear,
        applyPromoCode,
        removePromoCode,
        promoCode,
        applyShip,
        ship,
        expeditedShippingNoticeAcknowledged,
        setExpeditedShippingNoticeAcknowledged,
      }}
    />
  )
}
