const theme = {
  color: {
    background: {
      dark: '#172126', // TODO: possibly remove
      darkPurple: '#2E2738',
      light: '#FFF',
      lightGray: '#F6F6F6',
      gray: '#F2F5F8',
      lightBlue: '#E7ECF8',
      bone: '#F6F6F0',
      blue: '#515AC4',
    },
    text: {
      light: '#FFF',
      dark: '#192125',
      blue: '#C6D3DE',
      lightGrey: '#B3B3B3',
      gray: '#62686C',
      darkPurple: '#2E2738',
      green: '#6BB58E',
      brown: '#82592B', // Used on light backgrounds
      gold: '#DAAE67', // Used on dark backgrounds
    },
    accent: {
      primary: '#FF5D1A',
      secondary: '#B38D44',
      gold: '#DAAE67',
      positive: '#5fC499',
    },
    border: {
      light: '#D8D8D8',
      blue: '#C6D3DE',
      lighter: '#EBEAEA',
    },
    line: {
      normal: '#C6D3DE',
    },
    score: [
      { value: 0, color: '#FF4131' },
      { value: 40, color: '#FFBF00' },
      { value: 70, color: '#5FC499' },
    ],
    error: '#D81100',
    success: '#5FC499',
    link: {
      blue: '#0074D7',
      navy: '#005195',
    },
  },
}

export const getColorByScore = (score: number): string => {
  let currentIndex = ''

  theme.color.score.forEach(threshold => {
    if (threshold.value > score) {
      return
    }

    currentIndex = threshold.color
  })

  return currentIndex
}

export default theme
