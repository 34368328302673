import { css } from 'styled-components'
import mq from 'styles/mq'
import theme from './theme'

const font = {
  base: '"Suisse Intl", sans-serif',
  headings: '"AvantGarde", sans-serif',
}

const heading = {
  xxxl: css`
    font: bold 4.4rem/1.04 ${font.headings};
    letter-spacing: -1px;

    ${mq.medium} {
      font-size: 8.5rem;
    }

    ${mq.large} {
      font-size: 10.4rem;
    }

    ${mq.extraLarge} {
      font-size: 15rem;
    }
  `,
  xxl: css`
    font: bold 4.4rem/1.0625 ${font.headings};
    letter-spacing: -1px;

    ${mq.medium} {
      font-size: 6.4rem;
    }
  `,
  xl: css`
    font: bold 3.2rem/1.083 ${font.headings};
    letter-spacing: -1px;
    margin-bottom: 1.6rem;

    ${mq.medium} {
      font-size: 4.4rem;
      margin-bottom: 2.8rem;
    }
  `,
  l: css`
    font: bold 3.2rem/1.25 ${font.headings};
    letter-spacing: -1px;
  `,
  m: css`
    font: bold 2.4rem/1.67 ${font.headings};
    letter-spacing: -1px;
  `,
  // Small headings should use the base font rather than the headings font
  s: css`
    font: bold 1.8rem/1.56 ${font.base};
    letter-spacing: normal;

    ${mq.medium} {
      letter-spacing: normal;
    }
  `,
  xs: css`
    font: bold 1.6rem/1.25 ${font.base};
    letter-spacing: normal;
  `,
  xxs: css`
    text-transform: uppercase;
    font: bold 1.2rem/1.04 ${font.headings};
    color: ${theme.color.accent.secondary};
    letter-spacing: normal;
    margin-bottom: 0.5rem;
  `,
  black: css`
    text-transform: uppercase;
    font: bold 1.2rem/1.2 ${font.headings};
    letter-spacing: 0.1em;
  `,
  eyebrow: css`
    font-family: ${font.base};
    font-weight: bold;
    line-height: 1.4rem;
    font-size: 1.4rem;
  `,
  nav: css`
    font-family: ${font.headings};
    line-height: 3.7rem;
    font-size: 3.2rem;
    letter-spacing: normal;

    ${mq.medium} {
      line-height: 1.9rem;
      font-size: 1.9rem;
      letter-spacing: 0;
    }
  `,
}

const paragraph = {
  normal: css`
    font-size: 1.8rem;
    line-height: 1.33;
    font-family: ${font.base};
  `,
  large: css`
    line-height: 1.33;
    font-size: 2.2rem;
    font-family: ${font.base};
  `,
  small: css`
    line-height: 1.5;
    font-size: 1.6rem;
    font-family: ${font.base};
  `,
  extraSmall: css`
    line-height: 1.33;
    font-size: 1.2rem;
    font-family: ${font.base};
  `,
  xlBody: css`
    font-family: ${font.base};
    line-height: 4.2rem;
    font-size: 3.2rem;
    font-weight: normal;
  `,
  lBody: css`
    font-family: ${font.base};
    line-height: 4.3rem;
    font-size: 2.4rem;
  `,
  mBody: css`
    font-family: ${font.base};
    line-height: 3.2rem;
    font-size: 2.1rem;
  `,
  sBody: css`
    font-family: ${font.base};
    line-height: 2.9rem;
    font-size: 1.9rem;
  `,
  xsBody: css`
    font-family: ${font.base};
    line-height: 2.4rem;
    font-size: 1.6rem;
  `,
}

export { font, heading, paragraph }
