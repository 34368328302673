import styled, { css } from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'
import Spinner from 'components/spinner'

type ComponentProps = {
  inverted?: boolean
  hidden?: boolean
}

export const Wrapper = styled.form<ComponentProps>`
  padding-bottom: 1rem;
  border-bottom: 0.3rem solid ${theme.color.accent.primary};
  display: grid;
  grid-template-columns: 1fr auto;

  svg > g {
    stroke: ${theme.color.accent.primary};
  }
  ${props =>
    props.inverted &&
    css`
      border-bottom: 0.3rem solid ${theme.color.text.light};
      svg > g {
        stroke: ${theme.color.text.light};
      }
    `}
`

export const Title = styled.h5`
  ${heading.m};
  margin-bottom: 1rem;

  ${mq.medium} {
    margin-bottom: 2rem;
  }
`

export const Input = styled.input<ComponentProps>`
  border: none;
  background: none;
  ${paragraph.small};
  color: ${theme.color.text.dark};
  ${props =>
    props.inverted &&
    css`
      color: ${theme.color.text.light};
    `}
`

export const Button = styled.button<ComponentProps>`
  background: none;
  border: none;
  cursor: pointer;
  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`

export const SubmitSpinner = styled(Spinner as any)<ComponentProps>`
  color: ${theme.color.accent.primary};
  margin-left: -2rem;
  margin-top: 1rem;
  ${props =>
    props.inverted &&
    css`
      color: ${theme.color.text.light};
    `}
`
