import React, { useEffect, useState, FC, ComponentProps } from 'react'
import { Helmet } from 'react-helmet'

export const withIntersectionObserver = (Component: FC) => (
  props: ComponentProps<any>
) => {
  const [polyfillsReady, setPolyfillsReady] = useState(
    typeof window === 'undefined' || !!window.IntersectionObserver
  )

  useEffect(() => {
    if (!polyfillsReady) {
      const js = document.createElement('script')
      js.src =
        'https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver%2CIntersectionObserverEntry'
      js.onload = function() {
        setPolyfillsReady(true)
      }
      js.onerror = function() {
        setPolyfillsReady(false)
      }
      document.head.appendChild(js)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!polyfillsReady) {
    return null
  }

  return <Component {...props} />
}

export const withSmoothScroll = (Component: FC) => (
  props: ComponentProps<any>
) => {
  const [supportsSmoothScroll, setSupportsSmoothScroll] = useState(true)

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      !('scrollBehavior' in document.documentElement.style)
    ) {
      setSupportsSmoothScroll(false)
    }
  }, [])

  return (
    <>
      {!supportsSmoothScroll && (
        <Helmet>
          <script
            type="text/javascript"
            src="https://unpkg.com/smoothscroll-polyfill/dist/smoothscroll.min.js"
          ></script>
        </Helmet>
      )}

      <Component {...props} />
    </>
  )
}
