import styled from 'styled-components'
import Whale from 'components/icons/whale'
import LeadEmailCaptureForm from 'components/lead-email-capture-form'
import theme from 'styles/theme'
import { font, paragraph } from 'styles/typography'
import mq from 'styles/mq'

export const Wrapper = styled.footer`
  margin-top: auto;
  color: ${theme.color.text.light};
  background: ${theme.color.background.darkPurple};
`

export const Inner = styled.div`
  display: block;
  max-width: 108rem;
  margin: 0 auto;
  padding: 6.4rem 3rem;

  ${mq.medium} {
    padding: 10rem 3rem;
    display: grid;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-areas: 'logo links form' 'logo links social' 'logo links copyright';
  }

  ${mq.large} {
    padding: 10rem 0;
  }
`

export const Logo = styled(Whale)`
  grid-area: logo;
  margin-bottom: 4rem;
  font-size: 7.5rem;
  margin-top: -2rem;
  margin-left: -1rem;
  color: ${theme.color.accent.secondary};
`

export const Social = styled.ul`
  list-style: none;
  padding: 0;
  grid-area: social;
  margin-bottom: 7.3rem;

  li {
    display: inline-block;
    margin-right: 3.2rem;
  }

  ${mq.medium} {
    margin-bottom: 10rem;
  }
`

export const Links = styled.ul`
  list-style: none;
  padding: 0;
  grid-area: links;
  max-width: 38rem;
  column-count: 2;
  margin: 0 0 5.6rem;
  text-transform: capitalize;
  font: bold 1.6rem ${font.headings};

  ${mq.medium} {
    font-size: 1.8rem;
  }

  li {
    margin-bottom: 1.6rem;
    display: inline-block;
    width: 100%;
  }

  a {
    color: ${theme.color.text.light};

    &:hover {
      color: ${theme.color.accent.primary};
    }
  }
`

export const Newsletter = styled(LeadEmailCaptureForm)`
  font-size: 2.4rem;
  grid-area: form;
  margin-bottom: 5.6rem;

  ${mq.medium} {
    margin-bottom: 4.8rem;
  }

  h5 {
    margin-top: 0;
  }
`

export const Copyright = styled.div`
  grid-area: copyright;
  ${paragraph.extraSmall};
`
