export enum ScreenSize {
  XS = 320,
  SM = 375,
  MLG = 425,
  MD = 768,
  D = 1024,
  LG = 1180,
  XL = 1800,
}

export const MaxWidth = 1378
export const ProseWidth = '65ch'

const mq = {
  extraSmall: `@media (min-width: ${ScreenSize.XS / 16}em)`,
  small: `@media (min-width: ${ScreenSize.SM / 16}em)`,
  mobileLarge: `@media (min-width: ${ScreenSize.MLG / 16}em)`,
  medium: `@media (min-width: ${ScreenSize.MD / 16}em)`,
  desktop: `@media (min-width: ${ScreenSize.D / 16}em)`,
  large: `@media (min-width: ${ScreenSize.LG / 16}em)`,
  extraLarge: `@media (min-width: ${ScreenSize.XL / 16}em)`,
}

export default mq
