import { createGlobalStyle } from 'styled-components'
import theme from 'styles/theme'
import { font } from 'styles/typography'

export default createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    font-size: 62.5%;
    font-family: ${font.base};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialiased;
  }

  body {
    font-size: 1.6rem;
    background: ${theme.color.background.gray};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-family: ${font.headings};
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    a {
      color: ${theme.color.link.blue};

      &:hover,
      &:focus {
        color: ${theme.color.link.navy};
      }
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  blockquote {
    margin: 0;
  }

  input {
    font-family: ${font.base};
  }

  #___gatsby {
    height: 100%;
  }

  #gatsby-focus-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .intercom-lightweight-app-launcher {
    bottom: 90px !important;
  }

  sup {
    font-size: 1rem;
    line-height: 0;

    &::after {
      content: " ";
    }
  }
`
