import styled from 'styled-components'

import theme from 'styles/theme'
import LogoBase from 'components/logo'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: ${theme.color.background.lightBlue};

  svg {
    min-height: 20rem;
    padding-bottom: 3rem;
  }
`
export const DetailCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background: ${theme.color.background.light};
  text-align: center;
  font-size: 16px;
`
export const Logo = styled(LogoBase)`
  font-size: 10rem;
`
